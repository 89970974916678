@import "~commons/src/style/variables/media";
@import "~commons/src/style/variables/colors";
@import "~commons/src/style/variables/fonts";


.building-config {
    &__wrapper {
        margin-bottom: 20px;
    }
    &__text {
        white-space: pre-wrap;
        margin-bottom: 0;
    }
    &__radio {
        width: 100%;
        align-items: baseline;
        margin-top: 20px;
        label {
            width: calc(100% - 40px);
        }
    }
}
