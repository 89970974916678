@import '../../style/variables/colors';
@import '../../style/variables/fonts.scss';

.buttonWrapper {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
}

.center {
    justify-content: center;
}
.right {
    justify-content: flex-end;
}
