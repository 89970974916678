@import 'commons/src/style/variables/fonts.scss';
@import 'commons/src/style/variables/colors.scss';

.reduceSampleRate {
    margin-bottom: 15px;
}

.reduceSampleRateHeader {
    font: $font-medium $regular;
    color: $black-shark;
    margin: 0 0 15px 0;
}

.estimatedBatteryLifeText {
    font: $font-medium $regular;
    color: $oslo-gray;
    white-space: pre-wrap;
    margin-bottom: 20px;
}

.toggleContainer {
    display: flex;
    flex-wrap: wrap;
    margin-left: auto;

}

.sensorSelector {
    min-width: 200px;
    width: 50%;
}

.textWrapper {
    display: flex;
    align-items: center;
    gap: 10px;
}

.subText {
    font: $font-small $regular;
    color: $black-shark;
}

.bulletList {
    composes: subText;
    padding-bottom: 20px;
    padding-left: 32px;
    white-space: pre-wrap;
}

.usbCableImage {
    width: 80px;
}
