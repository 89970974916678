@import './variables/colors.scss';
@import './variables/fonts.scss';
@import './variables/media.scss';

.segment-properties-form {
    display: flex;
    flex-direction: column;

    &--blur {
        filter: blur(2px);
    }

    &__header {
        margin-bottom: 5px;
    }

    &__text {
        font: 13px/18px $regular;
        margin-bottom: 20px;
    }
    &__battery-estimate {
        color: $black-shark;
        margin-top: 20px;
        &--blue {
            font: 34px/46px $demi-bold;
            color: $blue-aura;
            padding-left: 10px;
        }
    }

    &__fields {
        display: flex;

        > *:first-child {
            flex: 1;
        }
        > *:not(:first-child) {
            flex: 1;
            margin-left: 12px;
        }

        &__volume input {
            border-color: transparent;
        }
    }
}
