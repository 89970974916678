@import '../../style/variables/colors';
@import '../../style/variables/fonts.scss';

.content {
    width: 100%;
    margin: 0 auto 20px auto;
}
.slim {
    max-width: 500px;
}
.marginTop {
    margin-top: 20px;
}
