.add-device {
    &__devices-added {
        width: 100%;
        margin-bottom: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        &__of-type {
            display: flex;
            flex-wrap: wrap;
            .form__field--medium-slim {
                width: 120px;
                margin-bottom: 15px;
            }
        }
        &__expand {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            margin-left: auto;
            margin-bottom: 15px;
        }
    }
}
